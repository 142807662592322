<template>
    <div class="overflow-hidden">
        <header id="topnav" class="defaultscroll sticky">
            <div class="">
                <!-- Logo container-->
                <router-link to="/forfait" class="logo">
                    <span class="logo-light-mode">
                        <img src="/assets/images/logo.png" class="l-dark" height="44" alt="">
                        <img src="/assets/images/logo.png" class="l-light" height="44" alt="">
                    </span>
                    <img src="/assets/images/logo.png" height="44" class="logo-dark-mode" alt="">
                </router-link>

                <!-- End Logo container-->
                <div class="menu-extras">
                    <div class="menu-item">
                        <!-- Mobile menu toggle-->
                        <!-- <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                                    <div class="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a> -->
                        <!-- End mobile menu toggle-->
                    </div>
                </div>

                <!--Login button Start-->
                <ul class="buy-button list-inline mb-0">
                    <li class="list-inline-item mb-0">
                        <router-link to="/login" class="sub-menu-item text-capitalize text-light fs-6 fw-bold">CONNEXION
                        </router-link>
                    </li>
                </ul>
                <!--Login button End-->

                <!--end navigation-->
            </div>
            <!--end container-->
        </header>
        <!-- Hero Start -->
        <section class="app-content content bg-half-100 d-table w-100" style="margin-top: -50px;">
            <div class="row align-items-center">
                <div class="col-md-6 col-lg-6 mx-auto">
                    <div class="d-lg-none">
                        <ul class="nav nav-justified rounded" id="pills-tab" role="tablist">
                            <li class="nav-item nav-item_mobile" v-for="(categorie, index) in categories" :key="index">
                                <button class="nav-link rounded btn btn-link"
                                    :class="categorie.slug === slug_forfait_active ? 'active' : ''" id="pills-cloud-tab"
                                    data-bs-toggle="pill" role="tab" aria-controls="pills-cloud" aria-selected="false"
                                    @click="getSlug(categorie.slug)" active-class="active">
                                    <div class="text-center">
                                        <h6 class="text-light fs-6 fw-bold">{{ categorie.name }}</h6>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="d-none d-lg-block">
                        <ul class="nav nav-justified flex-column flex-sm-row rounded sticky-bar " id="pills-tab"
                            role="tablist">
                            <li class="nav-item" v-for="(categorie, index) in categories" :key="index">
                                <button class="nav-link rounded btn btn-link"
                                    :class="categorie.slug === slug_forfait_active ? 'active' : ''" id="pills-cloud-tab"
                                    data-bs-toggle="pill" role="tab" aria-controls="pills-cloud" aria-selected="false"
                                    @click="getSlug(categorie.slug)" active-class="active">
                                    <div class="text-center">
                                        <h6 class="text-light fs-6 fw-bold text-capitalize">{{ categorie.name }}
                                        </h6>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 mx-auto">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-md custom-card">
                                <div class="card-body">
                                    <div class="accordion" id="buyingquestion" v-if="!loading_categorie">
                                        <ul class="mb-3">
                                            <div class="border-bottom" v-for="(pack, index) in categorie.packs"
                                                :key="index">
                                                <div class="d-flex justify-content-between lh-sm p-3" id="headingOne">
                                                    <div>
                                                        <h5 class="my-0 fw-bold pack_name">{{ pack.name }}
                                                        </h5>
                                                        <small class="text-desc">{{ pack.min_description
                                                            }}</small>
                                                    </div>
                                                    <div class="text-end">
                                                        <button class="btn btn-outline-primary mt-2 me-2"
                                                            data-bs-toggle="collapse"
                                                            :data-bs-target="`#collapse${pack.slug}`"
                                                            aria-expanded="true" aria-controls="collapseOne"><span
                                                                class="price">{{
                                                                pack.price.toLocaleString() }} GNF
                                                            </span></button>

                                                    </div>
                                                </div>
                                                <div :id="`collapse${pack.slug}`"
                                                    class="accordion-collapse border-0 collapse"
                                                    aria-labelledby="headingOne" data-bs-parent="#buyingquestion">
                                                    <div class="accordion-body text-muted">
                                                        <h5 class="fs-6">Informations personnelles
                                                        </h5>
                                                        <nav class="nav nav-pills nav-justified flex-column flex-sm-row rounded"
                                                            style="background: #ffff;">
                                                            <button class="nav-link active btn btn-outline me-2"
                                                                @click="getChoice('phone')"
                                                                :id="`nav-phone-tab${pack.slug}`" data-bs-toggle="tab"
                                                                :data-bs-target="`#nav-phone${pack.slug}`" type="button"
                                                                role="tab" :aria-controls="`nav-phone${pack.slug}`"
                                                                aria-selected="true">
                                                                Téléphone </button> <span
                                                                class="me-4 mt-1 pt-1">ou</span>
                                                            <button class="nav-link me-2" @click="getChoice('email')"
                                                                :id="`nav-mail-tab${pack.slug}`" data-bs-toggle="tab"
                                                                :data-bs-target="`#nav-mail${pack.slug}`" type="button"
                                                                role="tab" :aria-controls="`nav-mail${pack.slug}`"
                                                                aria-selected="true">
                                                                Mail
                                                            </button>
                                                        </nav>

                                                        <div class="alert bg-soft-danger fw-medium mt-2" role="alert"
                                                            v-if="error">
                                                            <i
                                                                class="uil uil-exclamation-octagon fs-6 align-middle me-1"></i>
                                                            {{ errorMessage }}
                                                        </div>

                                                        <!-- <PaymentForm /> -->
                                                        <form @submit.prevent="payOrder(pack.slug)">
                                                            <div class="tab-content" id="nav-tabContent">
                                                                <div class="tab-pane fade show active"
                                                                    :id="`nav-phone${pack.slug}`" role="tabpanel"
                                                                    :aria-labelledby="`nav-phone-tab${pack.slug}`">
                                                                    <div class="row mt-3">
                                                                        <div class="col-md-6">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"
                                                                                    for="phone">Téléphone</label>
                                                                                <div
                                                                                    class="form-icon position-relative">
                                                                                    <i
                                                                                        class="fea icon-sm icons fa fa-phone"></i>
                                                                                    <input name="phone" type="tel"
                                                                                        id="phone"
                                                                                        class="form-control ps-5"
                                                                                        :class="v$.phone.$error ? 'is-invalid' : ''"
                                                                                        v-model="form.phone"
                                                                                        @input="v$.phone.$touch()"
                                                                                        maxlength="9"
                                                                                        placeholder="Numéro de téléphone">
                                                                                </div>
                                                                                <div class="text-danger"
                                                                                    v-if="v$.phone.$error">
                                                                                    {{
                                                                                    v$.phone.$errors[0].$message
                                                                                    }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"
                                                                                    for="phone">Confirmer
                                                                                    téléphone</label>
                                                                                <div
                                                                                    class="form-icon position-relative">
                                                                                    <i
                                                                                        class="fea icon-sm icons fa fa-phone"></i>
                                                                                    <input name="phone" type="tel"
                                                                                        id="phone"
                                                                                        class="form-control ps-5"
                                                                                        :class="v$.confirm_phone.$error ? 'is-invalid' : ''"
                                                                                        v-model="form.confirm_phone"
                                                                                        @input="v$.confirm_phone.$touch()"
                                                                                        maxlength="9"
                                                                                        placeholder="Rétaper le même numéro">
                                                                                </div>
                                                                                <div class="text-danger"
                                                                                    v-if="v$.confirm_phone.$error">
                                                                                    {{
                                                                                    v$.confirm_phone.$errors[0].$message
                                                                                    }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="tab-pane fade" :id="`nav-mail${pack.slug}`"
                                                                    role="tabpanel"
                                                                    :aria-labelledby="`nav-mail-tab${pack.slug}`">
                                                                    <div class="row mt-3">
                                                                        <div class="col-md-6">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"
                                                                                    for="email">Email</label>
                                                                                <div
                                                                                    class="form-icon position-relative">
                                                                                    <i
                                                                                        class="fea icon-sm icons fa fa-envelope"></i>
                                                                                    <input name="email" id="email"
                                                                                        type="email"
                                                                                        class="form-control ps-5"
                                                                                        :class="v$.email.$error ? 'is-invalid' : ''"
                                                                                        v-model="form.email"
                                                                                        @input="v$.email.$touch()"
                                                                                        placeholder="Email">
                                                                                </div>
                                                                                <div class="text-danger"
                                                                                    v-if="v$.email.$error">
                                                                                    {{
                                                                                    v$.email.$errors[0].$message
                                                                                    }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="mb-3">
                                                                                <label class="form-label"
                                                                                    for="email">Confirmer
                                                                                    l'email</label>
                                                                                <div
                                                                                    class="form-icon position-relative">
                                                                                    <i
                                                                                        class="fea icon-sm icons fa fa-envelope"></i>
                                                                                    <input name="email" id="email"
                                                                                        type="email"
                                                                                        class="form-control ps-5"
                                                                                        :class="v$.confirm_email.$error ? 'is-invalid' : ''"
                                                                                        v-model="form.confirm_email"
                                                                                        @input="v$.confirm_email.$touch()"
                                                                                        placeholder="Rétaper le même email">
                                                                                </div>
                                                                                <div class="text-danger"
                                                                                    v-if="v$.confirm_email.$error">
                                                                                    {{
                                                                                    v$.confirm_email.$errors[0].$message
                                                                                    }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label class="form-label" for="name">Nom
                                                                            complet</label>
                                                                        <div class="form-icon position-relative">
                                                                            <i class="fea icon-sm icons fa fa-user"></i>
                                                                            <input name="name" id="name" type="text"
                                                                                class="form-control ps-5"
                                                                                :class="v$.name.$error ? 'is-invalid' : ''"
                                                                                v-model="form.name"
                                                                                @input="v$.name.$touch()"
                                                                                placeholder="Nom complet">
                                                                        </div>
                                                                        <div v-if="v$.name.$error" class="text-danger">
                                                                            <p>Le nom complet est
                                                                                obligatoire</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label class="form-label">Choisir le
                                                                            mode de
                                                                            paiement</label>
                                                                        <select class="form-select form-control"
                                                                            :class="v$.via.$error ? 'is-invalid' : ''"
                                                                            @input="v$.via.$touch()" v-model="form.via">
                                                                            <option value="">Choisir un mode de
                                                                                paiement
                                                                            </option>
                                                                            <template
                                                                                v-for="(payment, index) in payments"
                                                                                :key="index">
                                                                                <option :value="payment.slug">{{
                                                                                    payment.name }}</option>
                                                                            </template>
                                                                        </select>
                                                                        <div v-if="v$.via.$error" class="text-danger">
                                                                            <p>Le mode de paiement est
                                                                                obligatoire</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6" v-if="form.via === 'MoMo'">
                                                                    <div class="mb-3">
                                                                        <label class="form-label" for="phone">Numéro
                                                                            MoMo</label>
                                                                        <div class="form-icon position-relative">
                                                                            <i
                                                                                class="fea icon-sm icons fa fa-phone"></i>
                                                                            <input name="phone" type="tel" id="phone"
                                                                                :class="v$.numero_momo.$error ? 'is-invalid' : ''"
                                                                                class="form-control ps-5"
                                                                                @input="v$.numero_momo.$touch()"
                                                                                v-model="form.numero_momo" maxlength="9"
                                                                                placeholder="Numéro MoMo">
                                                                        </div>
                                                                        <div class="text-danger"
                                                                            v-if="v$.numero_momo.$error">
                                                                            {{
                                                                            v$.numero_momo.$errors[0].$message
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 offset-md-2 mt-3 pt-1 text-end"
                                                                    v-if="form.via === 'MoMo'">
                                                                    <button type="submit"
                                                                        :disabled="loading || v$.$invalid"
                                                                        class="btn btn-outline-primary mt-2 me-2">
                                                                        <span v-if="!loading">Acheter
                                                                            <i
                                                                                class="fea icon-sm icons fa fa-credit-card"></i></span>
                                                                        <div class="text-center" v-else>
                                                                            <div class="spinner-border spinner-border-sm"
                                                                                role="status">
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                                <div class="col-md-4 offset-md-8 mt-4 pt-2 text-end"
                                                                    v-else>
                                                                    <button type="submit"
                                                                        :disabled="loading || v$.$invalid"
                                                                        class="btn btn-outline-primary mt-2 me-2">
                                                                        <span v-if="!loading">Acheter <i
                                                                                class="fea icon-sm icons fa fa-credit-card"></i></span>
                                                                        <div class="text-center" v-else>
                                                                            <div class="spinner-border spinner-border-sm"
                                                                                role="status">
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <div v-if="showPagePayment">
                                                            <transition name="modal">
                                                                <div class="modal-mask">
                                                                    <div class="modal-wrapper">
                                                                        <div class="modal-dialog modal-lg modal-dialog-scrollable"
                                                                            role="document">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header">
                                                                                    <h5 class="modal-title">
                                                                                        Paiement
                                                                                        via Orange Money
                                                                                    </h5>
                                                                                    <button type="button"
                                                                                        class="btn btn-icon btn-close"
                                                                                        data-dismiss="modal"
                                                                                        aria-label="Close">
                                                                                        <span aria-hidden="true"
                                                                                            @click="closePagePayment()">
                                                                                            <i
                                                                                                class="uil uil-times fs-4 text-dark"></i>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="modal-body">
                                                                                    <div class="text-center"
                                                                                        v-if="!link_payment">
                                                                                        <div class="spinner-grow"
                                                                                            style="width: 5rem; height: 5rem;"
                                                                                            role="status">
                                                                                            <span
                                                                                                class="sr-only">Loading...</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <iframe v-else :src="link_payment"
                                                                                        width="100%" height="460"
                                                                                        frameborder="0"
                                                                                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
                                                                                        allowtransparency="true"></iframe>
                                                                                </div>
                                                                                <div class="modal-footer">
                                                                                    <button type="button"
                                                                                        class="btn btn-secondary btn-sm"
                                                                                        @click="closePagePayment()">Fermer</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </transition>
                                                        </div>

                                                        <div v-if="showCheckSatut">
                                                            <transition name="modal">
                                                                <div class="modal-mask">
                                                                    <div class="modal-wrapper">
                                                                        <div class="modal-dialog shadow-lg"
                                                                            role="document">
                                                                            <div class="modal-content">
                                                                                <div class="modal-body"
                                                                                    v-if="status === 'PENDING'">
                                                                                    <h5
                                                                                        class="text-center text-secondary">
                                                                                        Votre
                                                                                        paiement est en
                                                                                        attente de
                                                                                        confirmation</h5>
                                                                                    <h6 class="text-center"
                                                                                        style="font-size: 14px;">
                                                                                        Veuillez
                                                                                        confirmer le
                                                                                        paiement sur
                                                                                        votre téléphone MoMo
                                                                                    </h6>
                                                                                    <Timer />
                                                                                </div>
                                                                                <div class="modal-footer"
                                                                                    v-if="status === 'PENDING'">
                                                                                    <button type="button"
                                                                                        class="btn btn-secondary btn-sm"
                                                                                        @click="closeShowCheckSatut()">Fermer</button>
                                                                                </div>
                                                                                <div class="modal-body"
                                                                                    v-if="status === 'FAILED'">
                                                                                    <div
                                                                                        class="row justify-content-center">
                                                                                        <div class="py-4 my-4">
                                                                                            <h5 class="mb-5">
                                                                                                Votre
                                                                                                paiement a
                                                                                                échoué pour
                                                                                                l'une
                                                                                                des raisons
                                                                                                suivantes:
                                                                                            </h5>
                                                                                            <a href="#"
                                                                                                class="text-sm">1-Vous
                                                                                                n'avez pas
                                                                                                fourni
                                                                                                les bonnes
                                                                                                informations</a><br>
                                                                                            <a href="#"
                                                                                                class="text-sm">2-Votre
                                                                                                solde était
                                                                                                insuffissant</a>
                                                                                        </div>
                                                                                        <div
                                                                                            class="mt-6 card bg-light px-2 py-2">
                                                                                            <dl class="row mb-0">
                                                                                                <dt
                                                                                                    class="col-sm-2 h6 text-sm text-danger ">
                                                                                                    <i
                                                                                                        class="fas fa-exclamation-triangle fa-2x"></i>
                                                                                                </dt>
                                                                                                <dd
                                                                                                    class="col-sm-8 text-sm mt-1">
                                                                                                    S'il ne
                                                                                                    s'agit
                                                                                                    pas
                                                                                                    d'une de
                                                                                                    ces
                                                                                                    raisons,
                                                                                                    contactez
                                                                                                    le
                                                                                                    service
                                                                                                    d'assistance
                                                                                                    au
                                                                                                    .....
                                                                                                </dd>
                                                                                            </dl>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="modal-footer"
                                                                                    v-if="status === 'FAILED'">
                                                                                    <button type="button"
                                                                                        class="btn btn-secondary btn-sm"
                                                                                        @click="closeShowCheckSatut()">Fermer</button>
                                                                                </div>
                                                                                <div class="modal-body p-5"
                                                                                    v-if="status === 'SUCCESS'">
                                                                                    <dl
                                                                                        class="row justify-content-center">
                                                                                        <dt
                                                                                            class="col-sm-3 text-sucess">
                                                                                            <i class="fa fa-regular fa-circle-check fa-5x"
                                                                                                style="color:#4db469"></i>
                                                                                        </dt>
                                                                                        <dd class="col-sm-8 mt-4 pt-2 ">
                                                                                            <h5 class="text-secondary"
                                                                                                style="margin-left:-20px">
                                                                                                Paiement
                                                                                                effectué
                                                                                                avec
                                                                                                succès</h5>
                                                                                        </dd>
                                                                                    </dl>
                                                                                    <div class="mt-4 pt-2 text-center">
                                                                                        <router-link to="/login"
                                                                                            class="btn btn-success me-2 btn-sm">
                                                                                            Connectez-vous
                                                                                        </router-link>
                                                                                        <router-link to="/forfait"
                                                                                            class="btn btn-outline-success btn-sm">
                                                                                            Un
                                                                                            autre
                                                                                            Pass
                                                                                        </router-link>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </transition>
                                                        </div>

                                                        <div v-if="showPagePaymentVisa">
                                                            <transition name="modal">
                                                                <div class="modal-mask">
                                                                    <div class="modal-wrapper">
                                                                        <div class="modal-dialog modal-lg"
                                                                            role="document">
                                                                            <div class="modal-content">
                                                                                <div class="modal-header">
                                                                                    <h5 class="modal-title">
                                                                                        Paiement
                                                                                        avec Paycard de {{
                                                                                        amount }}
                                                                                    </h5>
                                                                                    <button type="button"
                                                                                        class="btn btn-icon btn-close"
                                                                                        data-dismiss="modal"
                                                                                        aria-label="Close">
                                                                                        <span aria-hidden="true"
                                                                                            @click="closePagePayment()">
                                                                                            <i
                                                                                                class="uil uil-times fs-4 text-dark"></i>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="modal-body">
                                                                                    <!-- <div class="text-center"
                                                                                                            v-if="!link_payment">
                                                                                                            <div class="spinner-grow"
                                                                                                                style="width: 5rem; height: 5rem;"
                                                                                                                role="status">
                                                                                                                <span
                                                                                                                    class="sr-only">Loading...</span>
                                                                                                            </div>
                                                                                                        </div> -->
                                                                                    <form
                                                                                        action="https://mapaycard.com/epay/"
                                                                                        method="POST">
                                                                                        <input type="hidden" name="c"
                                                                                            value="NTM2NTYyMDQ">
                                                                                        <input type="hidden"
                                                                                            name="paycard-amount"
                                                                                            :value="amount">
                                                                                        <!-- Changez au montant de votre produit -->
                                                                                        <input type="hidden"
                                                                                            name="paycard-description"
                                                                                            value="Vente de produit">
                                                                                        <!-- Changez à la description de la vente ( pas obligatoire ) -->

                                                                                        <!-- Inclure le callback URL où redigirer automatiquement après le paiement -->
                                                                                        <input type="hidden"
                                                                                            name="paycard-callback-url"
                                                                                            value="http://172.30.17.51:8081/api/paycard-callback">

                                                                                        <!-- Un appel POST sera fait au callback URL avec toutes les données -->
                                                                                        <!-- <input type="hidden" name="paycard-callback-url" value="https://www.monsite.com/check_payment"> -->

                                                                                        <!-- Faire la redirection en GET au lieu de POST -->
                                                                                        <!-- NB: Cette méthode est moins sécurisée que le POST. -->
                                                                                        <!-- Avec cette option activée, seule le champ transactionReference sera présent dans les données retournées par PayCard. -->
                                                                                        <input type="hidden"
                                                                                            name="paycard-redirect-with-get"
                                                                                            value="on">

                                                                                        <!-- Desactivez l'auto redirection en changeant la valeur de paycard-auto-redirect -->
                                                                                        <!-- <input type="hidden" name="paycard-auto-redirect" value="off"> -->

                                                                                        <!-- Inclure les données que vous aimerez recevoir au callback -->
                                                                                        <!-- ex : <input type="hidden" name="order_id" value="abc001"> -->
                                                                                        <input type="hidden"
                                                                                            name="paycard-jump-to-paycard"
                                                                                            value="on">

                                                                                        <input type="image"
                                                                                            src="https://mapaycard.com/static/images/paywithpaycard-cc.png"
                                                                                            border="0"
                                                                                            alt="Payez avec PayCard" />
                                                                                    </form>
                                                                                </div>
                                                                                <div class="modal-footer">
                                                                                    <button type="button"
                                                                                        class="btn btn-secondary btn-sm"
                                                                                        @click="closePagePayment()">Fermer</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                        <!--end row-->
                                                    </div>
                                                </div>
                                            </div>

                                        </ul>
                                    </div>
                                    <div class="d-flex justify-content-center" v-else>
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
    import useCategories from '../../services/categorieServices';
    import usePayments from '../../services/paymentServices';
    import Timer from '@/components/reusable/Timer.vue'
    import { onMounted, reactive, computed } from 'vue'
    import { useStore } from 'vuex';
    import useVuelidate from '@vuelidate/core'
    import { required, email, minLength, helpers, sameAs, requiredIf } from '@vuelidate/validators'
    // import PaymentForm from '../../components/PaymentForm.vue'
    export default {
        name: 'Souscription',
        components: {
            Timer,
            // PaymentForm
        },
        setup() {
            const store = useStore();
            const categories = computed(() => store.state.categories);
            const { getCategories, getCategorie, categorie, loading_categorie } = useCategories();
            const { payments, errorMessage, error, getPaymentMode, createOrder, order_response, closePagePayment, closeShowCheckSatut, link_payment, amount, status, loading, showPagePayment, showPagePaymentVisa, showCheckSatut, createPayOrderViaMoMo, createPayOrderViaVisa, createPayOrderViaOM,
            } = usePayments();
            const slug = localStorage.getItem('slug')
            const form = reactive({
                phone: '',
                confirm_phone: '',
                email: '',
                confirm_email: '',
                choice: 'phone',
                via: "",
                address: 'Madina Centre',
                name: '',
                numero_momo: ''
            });
            const rules = computed(() => {
                return {
                    name: { required },
                    phone: { requiredIf: helpers.withMessage("Le téléphone est obligatoire.", requiredIf(form.email == "")), minLength: helpers.withMessage("Le numéro invalide.", minLength(9)) },
                    confirm_phone: { sameAs: helpers.withMessage("Le numéros sont différents.", sameAs(form.phone)) },
                    email: { requiredIf: helpers.withMessage("L'addresse mail est obligatoire.", requiredIf(form.phone == "")), email: helpers.withMessage("L'addresse mail est invalide.", email) },
                    confirm_email: { sameAs: sameAs(form.email) },
                    via: { required },
                    numero_momo: { requiredIf: helpers.withMessage("L'addresse mail est obligatoire.", requiredIf(form.via == "MoMo")), minLength: helpers.withMessage("Le numéro invalide.", minLength(9)) }
                };
            })

            const v$ = useVuelidate(rules, form);

            onMounted(async () => {
                await getCategorie(slug);
                if (!categories.value) {
                    await getCategories();
                }

                await getPaymentMode();
            })

            const getSlug = (slug) => {
                getCategorie(slug);

            };

            const getChoice = (choice) => {
                form.choice = choice;
                console.log(form.choice);
            }

            const slug_forfait_active = computed(() => {
                return localStorage.getItem('slug');
            })

            const payOrder = async (slug_pack) => {
                if (form.choice === 'phone') {
                    if (form.phone === form.confirm_phone) {
                        const data = {
                            phone: form.phone,
                            name: form.name,
                            address: form.address,
                            choice: form.choice,
                            packs: [{
                                pack: slug_pack,
                                quantity: 1
                            }]
                        };

                        await createOrder(data);

                        console.log(order_response.value)

                        if (form.via == 'MoMo') {
                            const data_pay_order = {
                                via: form.via,
                                phone: form.numero_momo,
                                order: order_response.value.reference
                            };


                            await createPayOrderViaMoMo(data_pay_order);
                        }
                        else if (form.via === 'paycard') {
                            const data_pay_visa = {
                                via: form.via,
                                order: order_response.value.reference
                            };
                            createPayOrderViaVisa(data_pay_visa);
                        }
                        else if (form.via === 'OM') {
                            if (form.choice === 'phone') {
                                const data_pay_order = {
                                    via: form.via,
                                    phone: order_response.value.phone,
                                    order: order_response.value.reference
                                };

                                await createPayOrderViaOM(data_pay_order);
                            }
                            else {

                                const data_pay_order = {
                                    via: form.via,
                                    order: order_response.value.reference
                                };

                                await createPayOrderViaOM(data_pay_order);
                            }

                        }
                    }
                } else if (form.choice === 'email') {
                    if (form.email === form.confirm_email) {
                        const data = {
                            email: form.email,
                            name: form.name,
                            address: form.address,
                            choice: form.choice,
                            packs: [{
                                pack: slug_pack,
                                quantity: 1
                            }]
                        };

                        await createOrder(data);

                        if (form.via == 'MoMo') {
                            const data_pay_order = {
                                via: form.via,
                                phone: form.numero_momo,
                                order: order_response.value.reference
                            };


                            await createPayOrderViaMoMo(data_pay_order);
                        }
                        else if (form.via == 'PAYCARD') {
                            console.log("data");
                        }
                        else {
                            const data_pay_order = {
                                via: form.via,
                                order: order_response.value.reference
                            };
                            await createPayOrderViaOM(data_pay_order);
                        }
                    }
                }
            };

            return {
                categories,
                categorie,
                payments,
                form,
                slug_forfait_active,
                loading,
                link_payment,
                showPagePayment,
                showCheckSatut,
                status,
                loading_categorie,
                v$,
                amount,
                showPagePaymentVisa,
                errorMessage,
                error,
                getSlug,
                getChoice,
                payOrder,
                closePagePayment,
                closeShowCheckSatut,
            }
        }

    }


</script>

<style scoped>
    .bg-home {
        /* background: transparent linear-gradient(180deg, #007ABD 0%, #003D5F 100%); */
    }

    .custom-card {
        box-shadow: 0px 15px 30px #0000005D;
        border-radius: 30px;
        opacity: 1;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: rgba(0, 0, 0, .5); */
        backdrop-filter: blur(10px);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    /* iframe {
        overflow: scroll;
        width: 1349px;
        height: 100%;
        border: 1px solid black;
    } */

    h6 {
        font-size: 0.75rem;
    }

    .nav-link.active h6 {
        text-decoration: underline;
        text-decoration-thickness: 5px;
    }

    .nav-item_mobile {
        width: 60px;
    }

    .pack_name {
        color: #030000;
        font-size: 14px;
    }

    .text-desc {
        color: #707070;
        font-size: 14px;
    }

    .price {
        /* color: #707070; */
        font-size: 14px;
        font-weight: bold;
    }

    .nav-pills .nav-link {
        color: white;
        border: 1px solid #007ABD;
    }

    #topnav.nav-sticky {
        background: #007ABD;
    }

    @media (max-width: 991px) {
        #topnav {
            background: #007ABD;
        }
    }
</style>