<template>
    <div>
        <h5 class="h2 text-primary text-center font-weight-bold mb-4">{{ timeLeft }} </h5>
    </div>
</template>
<script>
    export default {
        name: 'Timer',
        props: {
            value: {
                default: 300
            }
        },
        data() {
            return {
                time: 300, //in seconds
                timer: null,
            }
        },
        methods: {
            decrementOrAlert() {
                if (this.time > 0) {
                    this.time--
                    // console.log(Math.floor(this.time / 60));
                    // setInterval(() => {
                    //     // checkStatusPaymentWithTimer(response.data);
                    //     console.log('suis la')
                    // }, 1000 * 60);
                    return;

                }




                // this.$router.push('/forfait');
                clearInterval(this.timer)
            },
        },
        computed: {
            timeLeft() {
                return `${this.minutes}:${this.seconds}s`
            },
            minutes() {
                return String(Math.floor(this.time / 60)).padStart(2, '0')
            },
            seconds() {
                return String(this.time % 60).padStart(2, '0')
            }
        },
        created() {
            this.time = this.value
            this.timer = setInterval(this.decrementOrAlert, 1000)
        },
    }
</script>