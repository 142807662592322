
import { ref } from 'vue'
import axios from 'axios'
export default function usePayments() {

    const payments = ref([]);
    const order_response = ref([]);
    let loading = ref(false);
    let showPagePayment = ref(false);
    let showPagePaymentVisa = ref(false);
    let showCheckSatut = ref(false);
    // let timer = ref(null);
    let link_payment = ref('');
    let transactionID = ref('');
    let amount = ref('');
    let status = ref('');
    // let timer = ref(null)
    // let time = ref(300); //in seconds
    let data_payment_orange = ref('');
    let data_payment_momo = ref('');
    const errorMessage = ref("");
    const error = ref(false)

    const getPaymentMode = async () => {
        try {
            let response = await axios.get('/payments/pay-mode/');
            payments.value = response.data.results;
        } catch (error) {
            error.value = true;
            if (error.response) {
                errorMessage.value = error.response.data;
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }

    };

    const createOrder = async (data) => {
        try {
            loading.value = true
            console.log(data)
            let response = await axios.post('/orders/', data);
            order_response.value = response.data;
            console.log(response.data);
            loading.value = false
        } catch (error) {
            loading.value = false;
            error.value = true;
            console.log(error)
            if (error.response) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }
    }


    const createPayOrderViaMoMo = async (data) => {
        try {
            loading.value = true;
            let response = await axios.post('/payments/pay-order/', data);
            console.log(response);
            if (response.status == 201) {
                console.log(response.data);
                loading.value = false;
                showCheckSatut.value = true
                status.value = response.data.status
                data_payment_momo.value = response.data
                checkStatusPaymentWithTimer(response.data)


                // let FIVE_MIN = 1000 * 60 * 5;
                // const msToNextRounded5Min = FIVE_MIN - (Date.now() % FIVE_MIN);

                // setTimeout(() => {
                //     checkStatusPaymentWithTimer(response.data);
                // }, msToNextRounded5Min);

                let minuteMS = 60 * 1000; // seconds * milliSeconds
                setTimeout(function () {
                    let timer = 0;

                    timer = setInterval(function () {
                        checkStatusPaymentWithTimer(response.data);
                        timer++;
                        console.log(timer)
                        if (timer >= 5) {
                            console.log("suis la")
                            clearInterval(timer);
                        }
                    }, minuteMS);
                    timer = 0;
                }, minuteMS);

            }
        } catch (error) {
            loading.value = false;
            error.value = true;
            console.log(error)
            if (error.response.status == 400) {
                errorMessage.value = error.response.data;
                console.log(error.response.data)
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }
    }


    const createPayOrderViaOM = async (data) => {
        try {
            loading.value = true
            let response = await axios.post('/payments/pay-order/', data);
            if (response.status == 201) {
                link_payment.value = response.data.extra.link
                showPagePayment.value = true
                loading.value = false
                data_payment_orange = response.data
            }
        } catch (error) {
            loading.value = false;
            error.value = true;
            if (error.response.status === 422) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }
    }

    const createPayOrderViaVisa = async (data) => {
        try {
            loading.value = true
            let response = await axios.post('/payments/pay-order/', data);
            if (response.status == 201) {
                // console.log("PayCard");
                // console.log(response.data.extra)

                showPagePaymentVisa.value = true
                transactionID.value = response.data.extra.transactionID
                amount.value = response.data.extra.amount
                console.log(response.data.extra.amount)
                loading.value = false
                // sendPayOrderPayCard(amount.value);

            }
        } catch (error) {
            loading.value = false;
            error.value = true;
            if (error.response.status === 422) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }
    }

    // const sendPayOrderPayCard = async (data) => {

    //     console.log("suils la");

    //     let response = await axios({
    //         method: 'post',
    //         url: 'https://mapaycard.com/epay/',
    //         data = Array(
    //             'c' => 'NTM2NTYyMDQ',
    //             'paycard-amount' => data
    //         ),
    //         // headers: { 'X-Custom-Header': 'foobar' }
    //     });

    //     console.log(response);
    // }

    const closePagePayment = async () => {
        showPagePayment.value = false
        showCheckSatut.value = true
        checkStatusPayment(data_payment_orange)
    }

    const closeShowCheckSatut = async () => {
        showCheckSatut.value = false
        // console.log(timer.value);
        // clearInterval(timer.value)
        // checkStatusPayment(data_payment_momo)

    }

    const checkStatusPayment = async (data) => {
        try {
            console.log(data)
            let data_status = {
                amount: data.amount,
                reference: data.reference
            }

            let response = await axios.post(`/payments/status/${data.slug}/`, data_status);
            console.log(response.data.status);
            status.value = response.data.status
        } catch (error) {
            loading.value = false;
            error.value = true;
            if (error.response.status === 422) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 404) {
                errorMessage.value = error.response.data.detail;
            }
            if (error.response.status === 400) {
                errorMessage.value = error.response.detail;
            }
            else {
                errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            }
        }

    }

    const checkStatusPaymentWithTimer = async (data) => {
        try {
            // console.log(data.value)
            let data_status = {
                amount: data.amount,
                reference: data.reference
            }

            let response = await axios.post(`/payments/status/${data.slug}/`, data_status);
            showCheckSatut.value = true
            status.value = response.data.status
            console.log(response.data);
        } catch (error) {
            loading.value = false;
            error.value = true;
            // if (error.response.status === 422) {
            //     errorMessage.value = error.response.data.detail;
            // }
            // if (error.response.status === 404) {
            //     errorMessage.value = error.response.data.detail;
            // }
            // if (error.response.status === 400) {
            //     errorMessage.value = error.response.detail;
            // }
            // else {
            //     errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
            // }
        }

    }




    return {
        payments,
        order_response,
        link_payment,
        amount,
        loading,
        showPagePayment,
        showPagePaymentVisa,
        showCheckSatut,
        status,
        errorMessage,
        error,
        getPaymentMode,
        createOrder,
        createPayOrderViaMoMo,
        createPayOrderViaOM,
        createPayOrderViaVisa,
        // sendPayOrderPayCard,
        closePagePayment,
        checkStatusPayment,
        checkStatusPaymentWithTimer,
        closeShowCheckSatut,
    }
}